@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Alegreya-Regular.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/IBMPlexSerif-Light.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Outfit-Regular.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/PlayfairDisplay-SemiBold.ttf') format('truetype');
}

  .btn-indigo {

  }
